import React from "react"
import { Link } from "gatsby"
import { RiArrowRightSLine, RiCheckLine, RiCloseLine } from "react-icons/ri"

import SEO from "../components/seo"
import Layout from "../components/layout"

const Belmot = () => (
  <Layout className="page">
    <SEO title="BELMOT Oldtimerversicherung – Überblick und Leistungen" />
    <div className="grid-container grid-container--start">
      <h1>
        BELMOT<sup>&reg;</sup> im Detail
      </h1>
      <h3 className="grid-container--start--h3">Sie haben die Wahl:</h3>
      <div className="grids col-1 sm-2">
        <p>
          <b>Grunddeckung</b> – mehr als nur Teilkasko und damit typisch BELMOT
          <sup>&reg;</sup>! Sie leistet zusätzlich bei Vandalismus und bei
          Schäden während des Transports, z. B. auf dem Weg zu einer Rallye.
        </p>
        <p>
          <b>Allgefahrendeckung</b> – die Premium-Variante von BELMOT
          <sup>&reg;</sup> ist leistungsstärker als eine Vollkaskoversicherung!
          Sie schützt bei nahezu allen Gefahren – auch bei Schäden, die nicht
          durch einen Unfall entstehen, wie Motorschaden, Bruchschaden und
          Getriebschaden.
        </p>
      </div>
    </div>
    <div className="grid-container grid-container--table">
      <div className="belmot__table-wrapper">
        <h2>Leistungsübersicht</h2>
        <table className="table">
          <thead>
            <tr>
              <th className="table__head">&nbsp;</th>
              <th className="table__head">Grunddeckung</th>
              <th className="table__head table__head--third">
                Allgefahrendeckung
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table__column table__column--first">Teilkasko</td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">Vollkasko</td>
              <td className="table__column table__column--second">
                <span className="table__redcross">
                  <RiCloseLine />
                </span>
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Brand, Explosion, Anprall und Absturz eines Flugkörpers, seiner
                Teile oder Ladung
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Entwendung, Diebstahl, unbefugter Gebrauch betriebsfremder
                Personen, Raub, räuberische Erpressung
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Elementarschäden, z.B. Sturm, Hagel, Blitzschlag,
                Überschwemmung, Erdbeben, Erdrutsch, Lawinen
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Kollision mit Tieren aller Art
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Vandalismus
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Transportschäden
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Havarie grosse (z.B. „Überbordwerfen“ des Oldtimers zur Rettung
                von Schiff oder Fähre und der Passagiere)
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Kurzschluss inkl. Folgeschäden (bis zur vereinbarten
                Versicherungssumme)
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Glasbruchschäden (genereller Selbstbehalt 150 Euro, unabhängig
                vom festgelegten Selbstbehalt)
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Tierbissschäden inkl. Folgeschäden (bis zur vereinbarten
                Versicherungssumme)
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Grobe Fahrlässigkeit (nicht unter Alkohol- und Drogeneinfluss)
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Schäden durch Tierfraß (z.B. durch Motten und Mäuse)
              </td>
              <td className="table__column table__column--second">
                bis 5.000 €
              </td>
              <td className="table__column table__column--third">
                bis zur vereinbarten Versicherungssumme
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Schäden durch herabfallende Gebäudeteile
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Schäden durch auslaufende Batterieflüssigkeit
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Bei bestehender Fuhrparkregelung: Rangierschäden mitversichert
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Bei sinkendem Fahrzeugwert: Leistung bis zur vereinbarten
                Versicherungssumme, wenn das Wertgutachten im Schadenfall nicht
                älter als
              </td>
              <td className="table__column table__column--second">
                <RiCheckLine />
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Vorsorgeversicherung bei Wertsteigerung, wenn das Wertgutachten
                im Schadenfall nicht älter als zwei Jahre ist
              </td>
              <td className="table__column table__column--second">30%</td>
              <td className="table__column table__column--third">30%</td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Verlust der Fahrzeugschlüssel infolge Einbruch/Raub
              </td>
              <td className="table__column table__column--second">
                bis 5.000 €
              </td>
              <td className="table__column table__column--third">
                bis 5.000 €
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Selbstverschuldete Unfallschäden (z.B. Auffahrunfall,
                Einparkschaden)
              </td>
              <td className="table__column table__column--second">
                <span className="table__redcross">
                  <RiCloseLine />
                </span>
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Bremsschaden (z.B. Lack-/Blechschäden durch verrutschte
                Gepäckstücke)
              </td>
              <td className="table__column table__column--second">
                <span className="table__redcross">
                  <RiCloseLine />
                </span>
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Betriebsschaden (z.B. Motorhaube fliegt auf und zerstört die
                Windschutzscheibe)
              </td>
              <td className="table__column table__column--second">
                <span className="table__redcross">
                  <RiCloseLine />
                </span>
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Bruchschäden soweit diese nicht aufgrund von Verschleiß oder
                Abnutzung entstanden sind (z.B. Rahmenbruch, Achsenbruch)
              </td>
              <td className="table__column table__column--second">
                <span className="table__redcross">
                  <RiCloseLine />
                </span>
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Getriebeschäden (z.B. durch Bruch eines Zahnrads)
              </td>
              <td className="table__column table__column--second">
                <span className="table__redcross">
                  <RiCloseLine />
                </span>
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Motorschäden (z.B. Abriss eines Pleuels)
              </td>
              <td className="table__column table__column--second">
                <span className="table__redcross">
                  <RiCloseLine />
                </span>
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Fehlbedienung (z.B. Verschalten)
              </td>
              <td className="table__column table__column--second">
                <span className="table__redcross">
                  <RiCloseLine />
                </span>
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
            <tr>
              <td className="table__column table__column--first">
                Chemische Reaktion (z.B. Lackverätzung)
              </td>
              <td className="table__column table__column--second">
                <span className="table__redcross">
                  <RiCloseLine />
                </span>
              </td>
              <td className="table__column table__column--third">
                <RiCheckLine />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="home__anfragebanner home__anfragebanner--small">
        <h3>Unverbindliches Angebot sichern</h3>
        <Link to="/formular" className="button button--secondary">
          Jetzt kostenfrei anfragen
          <span class="icon -right">
            <RiArrowRightSLine />
          </span>
        </Link>
      </div>
    </div>
  </Layout>
)

export default Belmot
